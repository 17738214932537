<template>
  <div id="app">
    <div class="container pt-5">
      <div class="row justify-content-center">
        <div class="col-md-7">
          <ImageSlider :slides="imageSlides"/>
          <section id="content">
            <Hero class="pt-4 pb-4" />
            <Player class="mb-5" embedId="411067851" provider="vimeo" />
            <Player class="mb-5" embedId="Va7dGQJNaDw" provider="youtube" />
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './scss/index.scss';

export default {
  name: 'App',
  data() {
    return {
      imageSlides: [
        {
          image: '/image/slide-1.jpg',
        },
        {
          image: '/image/slide-2.jpg',
        },
        {
          image: '/image/slide-3.jpg',
        },
        {
          image: '/image/slide-4.jpg',
        },
        {
          image: '/image/slide-5.jpg',
        },
        {
          image: '/image/slide-6.jpg',
        },
        {
          image: '/image/slide-7.jpg',
        },
        {
          image: '/image/slide-8.jpg',
        },
        {
          image: '/image/slide-9.jpg',
        },
      ],
    };
  }
}
</script>

