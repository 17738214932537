import Vue from 'vue';

// Async
const Hero = () => import(/* webpackChunkName: "components" */ './Hero.vue');
const Icon = () => import(/* webpackChunkName: "components" */ './Icon.vue');
const ImageSlider = () => import(/* webpackChunkName: "components" */ './ImageSlider.vue');
const Player = () => import(/* webpackChunkName: "components" */ './Player.vue');

// Bind
Vue.component('Hero', Hero)
Vue.component('Icon', Icon)
Vue.component('ImageSlider', ImageSlider)
Vue.component('Player', Player)